import "./App.css";
import {
  ApplicationMain,
  ContactPage,
  ApplicationMainMenu,
  EulaPrivacy,
} from "./components/common";
import { Routes, Route } from "react-router-dom";
import { useMenuItems } from "./components/common/hooks/useMenuItems";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  // Hooks
  const { mainMenuItemList } = useMenuItems();

  return (
    <div className="App">
      <ApplicationMainMenu
        id="application-main-menu"
        className="application-menu"
        menuItems={mainMenuItemList}
      />
      <Routes>
        <Route path="/home" element={<ApplicationMain />} />
        <Route path="/login" element={<ApplicationMain />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/eula_privacy" element={<EulaPrivacy />} />
        <Route path="/" element={<ApplicationMain />} />
        {/* <Route path="*" element={<Navigate to="/home" />} /> */}
      </Routes>
    </div>
  );
}

export default App;
