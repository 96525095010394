export const ContactPage: React.FC<{}> = () => {
  return (
    <div id="div-application-contact" className="div application-contact">
      <h1>Contact Us</h1>
      <p>
        Please contact us at
        <pre>
          <a href="mailto:sales@steamtownmedia.com">
            Sales: sales@steamtownmedia.com
          </a>
        </pre>
        <pre>
          <a href="mailto:support@steamtownmedia.com">
            Support: support@steamtownmedia.com
          </a>
        </pre>
      </p>
    </div>
  );
};
