import { MenuItem } from "../props/CommonPropTypes";
import { useNavigate } from "react-router-dom";

export const useMenuItems = () => {

    const navigate = useNavigate();

    const mainMenuItemList: MenuItem[] = 
    [
        {
            id: "btn-application-main-menu-home",
            className: "btn-application-main-menu",
            title: "Home",
            onClick: () => {
                navigate("/home");
            },
        },
        {
            id: "btn-application-main-menu-contact",
            className: "btn-application-main-menu",
            title: "Contact",
            onClick: () => {
                navigate("/contact");
            },
        },
/*         {
            id: "btn-application-main-menu-login",
            className: "btn-application-main-menu",
            title: "Login",
            onClick: () => {
                navigate("/login");
            },
        }, */
/*         {
            id: "btn-application-main-menu-eula",
            className: "btn-application-main-menu",
            title: "EULA/Privacy",
            onClick: () => {
                navigate("/eula_privacy");
                //window.open("https://www.apple.com/legal/internet-services/itunes/dev/stdeula/", "_blank");
            },
        }, */
    ];
    
    return {mainMenuItemList};

}