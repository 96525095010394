export const EulaPrivacy: React.FC<{}> = () => {
  return (
    <div
      id="div-application-eula-privacy"
      className="div application-eula-privacy"
    >
      <h1>Apple App Store EULA</h1>
      <p className="eula-body">
        This End-User License Agreement ("EULA") is a legal agreement between
        you and Steamtown Media Limited for the software application you have
        downloaded from the Apple App Store. By downloading or using the
        Application, you agree to be bound by the terms of this EULA. If you do
        not agree to the terms of this EULA, do not download or use the
        Application.
      </p>
      <p></p>
      <h1>Privacy Policy</h1>
      <p className="privacy-body">TBD</p>
    </div>
  );
};
