import { Navbar, Nav, Container } from "react-bootstrap/esm";
import { MenuProps } from "./props/CommonPropTypes";
import logoSource from "../../../src/stm_logo_2.jpg";
//import { LocaleDropdown } from "./LocaleDropdown";

export const ApplicationMainMenu: React.FC<MenuProps> = ({ ...props }) => {
  const logo = <img className="App-logo" src={logoSource} alt="Logo"></img>;

  return (
    <div id="application-main-menu" className="application-main-menu">
      <Navbar
        className="application-main-menu-container-navbar"
        bg="dark"
        data-bs-theme="dark"
      >
        <Container className="application-main-menu-container">
          <Navbar.Brand className="application-main-menu-brand" href="/home">
            {logo}
          </Navbar.Brand>
          <Nav className="me-auto">
            {props.menuItems.map((menuItem) => (
              <Nav.Item
                key={menuItem.id}
                className={menuItem.className}
                onClick={menuItem.onClick}
              >
                {menuItem.title}
              </Nav.Item>
            ))}
          </Nav>
          <Navbar.Text>
            <span className="stm-main-menu-header">
              <h1 className="stm-main-menu-header">Steamtown Media Limited</h1>
            </span>
          </Navbar.Text>
          {/*           <Navbar>
            <LocaleDropdown />
          </Navbar> */}
        </Container>
      </Navbar>
    </div>
  );
};
