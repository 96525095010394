//import { useWebAPI } from "./hooks/useWebAPI";

export const ApplicationMain: React.FC = ({ ...props }) => {
  // TODO: Implement postData
  //const { fetchData, postData } = useWebAPI();
  //const { fetchData } = useWebAPI();

  return (
    <div
      id="application-main"
      className="div application-main"
      application-local-name="stm-corporate"
    >
      <div id="application-main-body">
        <h1>Steamtown Media Limited</h1>
      </div>

      {/*       <button
        id="test"
        onClick={() => {
          fetchData().then((data) => {
            alert(data);
          });
        }}
      >
        Test Fetch
      </button> */}
    </div>
  );
};
